// ** React Imports
import { useState, useRef } from 'react';

// ** Next Imports
import Link from 'next/link';
import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  NextPage,
} from 'next';

import { getCsrfToken, signIn } from 'next-auth/react';
import type { Provider } from 'next-auth/providers';

// ** MUI Components
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Box, { BoxProps } from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import useMediaQuery from '@mui/material/useMediaQuery';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled, useTheme } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Typography, { TypographyProps } from '@mui/material/Typography';

// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline';
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form';

// ** Configs
import themeConfig from '../../configs/themeConfig';

// ** Layout Import
import BlankLayout from '../../@core/layouts/BlankLayout';

// ** Demo Imports
import FooterIllustrationsV2 from '../../views/pages/auth/FooterIllustrationsV2';
import { useRouter } from 'next/router';
import { useApp } from 'src/@carketa/app/AppContext';
import { CarketaIcon } from 'src/@carketa/components/icons/CarketaIcon';
import { hasVelocifi } from 'src/utils/velocifi/velocifi';
import { VelocifiImg } from 'src/@carketa/velocifi/VelocifyImg';

// ** Styled Components
const LoginIllustrationWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(20),
  paddingRight: '0 !important',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(10),
  },
}));

const LoginIllustration = styled('img')(({ theme }) => ({
  maxWidth: '48rem',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '35rem',
  },
}));

const RightWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: 450,
  },
}));

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 400,
  },
}));

const TypographyStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: { mt: theme.spacing(8) },
}));

const LinkStyled = styled('a')(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: theme.palette.primary.main,
}));

const defaultValues = {
  password: '',
  username: '',
};

interface FormData {
  username: string;
  password: string;
}

type LoginPageProps = {
  csrfToken: string;
  providers: Array<Provider>;
};

const LoginPage: NextPage<LoginPageProps> = ({ csrfToken }) => {
  const has_velocifi = hasVelocifi();

  const router = useRouter();
  const { callbackUrl, error } = router.query;
  const { isFlutter } = useApp();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const form = useRef<any>(null);

  // ** Hooks
  const theme = useTheme();
  const hidden = useMediaQuery(theme.breakpoints.down('md'));

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
    mode: 'onBlur',
  });

  const onSubmit = (data: FormData) => {
    signIn('userNameAndPassword', {
      username: data.username,
      password: data.password,
    });
  };

  return (
    <BlankLayout>
      <Box className="content-right">
        {!hidden ? (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoginIllustrationWrapper>
              <LoginIllustration
                alt="login-illustration"
                src={`/images/pages/sign-in-visual.svg`}
              />
            </LoginIllustrationWrapper>
            <FooterIllustrationsV2 />
          </Box>
        ) : null}
        <RightWrapper>
          <Box
            sx={{
              p: 12,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'background.paper',
            }}
          >
            <BoxWrapper>
              <Box
                sx={{
                  top: 30,
                  left: 40,
                  display: 'flex',
                  position: 'absolute',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CarketaIcon width={50} height={50} fill="black" />
                {has_velocifi ? (
                  <div className="pt-3">
                    <VelocifiImg width={200} height={90} />
                  </div>
                ) : (
                  <Typography
                    variant="h6"
                    sx={{
                      ml: 3,
                      lineHeight: 1,
                      fontWeight: 600,
                      textTransform: 'uppercase',
                      fontSize: '1.5rem !important',
                    }}
                  >
                    {themeConfig.templateName}
                  </Typography>
                )}
              </Box>
              {isFlutter && <Box sx={{ height: 50 }}></Box>}
              <Box sx={{ mb: 6 }}>
                <TypographyStyled variant="h5">
                  Welcome to{' '}
                  {has_velocifi ? 'Velocifi' : themeConfig.templateName}! 👋🏻
                </TypographyStyled>
                <Typography variant="body2" id={'loginAdvise'}>
                  Please sign-in to your account and start the adventure
                </Typography>
              </Box>
              {error && (
                <Box sx={{ mb: 6 }}>
                  <Alert icon={false} color="error" id={'loginError'}>
                    <Typography variant="body2">{error}</Typography>
                  </Alert>
                </Box>
              )}
              <form
                ref={form}
                noValidate
                autoComplete="off"
                method="post"
                action={`/api/auth/callback/credentials${
                  callbackUrl ? `?callbackUrl=${callbackUrl.toString()}` : ''
                }`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <input
                  name="csrfToken"
                  type="hidden"
                  defaultValue={csrfToken}
                />
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name="username"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        name="username"
                        autoFocus
                        label="Email"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={Boolean(errors.username)}
                      />
                    )}
                  />
                  {errors.username && (
                    <FormHelperText sx={{ color: 'error.main' }}>
                      {errors.username.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="auth-login-v2-password"
                    error={Boolean(errors.password)}
                  >
                    Password
                  </InputLabel>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <OutlinedInput
                        value={value}
                        onBlur={onBlur}
                        label="Password"
                        onChange={onChange}
                        id="auth-login-v2-password"
                        name="password"
                        error={Boolean(errors.password)}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onMouseDown={(e) => e.preventDefault()}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <EyeOutline />
                              ) : (
                                <EyeOffOutline />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                  {errors.password && (
                    <FormHelperText sx={{ color: 'error.main' }} id="">
                      {errors.password.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <Box
                  sx={{
                    py: 4,
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Link legacyBehavior passHref href="/forgot-password">
                    <LinkStyled id={'forgotPassword'}>
                      Forgot Password?
                    </LinkStyled>
                  </Link>
                </Box>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mb: 7 }}
                >
                  Login
                </Button>
                {/* {!isFlutter && (
                  <>
                    <Divider sx={{ my: 5 }}>or</Divider>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {Object.values(providers).map((provider) => {
                        if (
                          provider.name === 'Credentials' ||
                          provider.name === 'loginAs'
                        )
                          return;
                        if (provider.name === 'Google') {
                          return (
                            <Box key={provider.name}>
                              <IconButton
                                component="a"
                                onClick={(e: MouseEvent<HTMLElement>) => {
                                  e.preventDefault();
                                  signIn(provider.id, {
                                    callbackUrl: callbackUrl?.toString() || '',
                                  });
                                }}
                              >
                                <img
                                  id={'googleLogin'}
                                  alt="googleLogin"
                                  src={googleLoginLoad()?.initial}
                                  onMouseOver={(e) =>
                                    (e.currentTarget.src =
                                      googleLoginLoad().mouseOn)
                                  }
                                  onMouseLeave={(e) =>
                                    (e.currentTarget.src =
                                      googleLoginLoad().mouseOff)
                                  }
                                  onClick={(e) =>
                                    (e.currentTarget.src =
                                      googleLoginLoad().clicked)
                                  }
                                />
                              </IconButton>
                            </Box>
                          );
                        }
                        return <></>;
                      })}
                    </Box>
                  </>
                )} */}
              </form>
              {/* {!isFlutter && (
                <Link legacyBehavior passHref href="/signup">
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ mt: 7 }}
                  >
                    create an account
                  </Button>
                </Link>
              )} */}
            </BoxWrapper>
          </Box>
        </RightWrapper>
      </Box>
    </BlankLayout>
  );
};

export default LoginPage;

export const getServerSideProps: GetServerSideProps = async (
  ctx: GetServerSidePropsContext
) => {
  const csrfToken = await getCsrfToken(ctx);
  return {
    props: {
      csrfToken: csrfToken || null,
    },
  };
};
