type CarketaIconProps = {
  width?: number;
  height?: number;
  fill?: string;
};

export const CarketaIcon: React.FC<CarketaIconProps> = ({
  width = 32,
  height = 35,
  fill = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.93863 11.7151V22.7075L15.4998 28.2037L17.2918 27.1735L23.2379 30.5744L15.4998 35L0 26.1352V8.2874L14.4903 0V6.79929L5.93863 11.7151Z"
        fill={fill}
      />
      <path
        d="M31.002 8.2874V15.131L16.5117 6.79943V0L31.002 8.2874Z"
        fill={fill}
      />
      <path
        d="M31.002 17.178V24.0216L16.5117 15.6901V8.89062L31.002 17.178Z"
        fill={fill}
      />
      <path
        d="M16.5117 17.8438L31.002 26.1329L25.0366 29.5448L16.5117 24.6432V17.8438Z"
        fill={fill}
      />
    </svg>
  );
};
