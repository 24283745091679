import { useTheme } from '@mui/material';

type props = {
  width?: number;
  height?: number;
};

export const VelocifiImg: React.FC<props> = ({ width = 200, height = 90 }) => {
  const theme = useTheme();
  return (
    <img
      src={
        theme.palette.mode === 'dark'
          ? `/img/velocifi/VELOCIFI (ALL WHITE) (1).png`
          : `/img/velocifi/VELOCIFI (ALL BLACK).png`
      }
      alt="Velocifi"
      width={width}
      height={height}
    />
  );
};
